


<template>
    <b-card-text v-if="offer">
        <b-row>
            <b-col lg="6" sm="12">
                <b-row class="my-1">
                    <b-col> Project Name: <span class="font-weight-bold ml-1">{{ offer.project_name }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Estimated Duration: <span class="font-weight-bold ml-1">{{ offer.estimated_duration
                    }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Serial Number: <span class="font-weight-bold ml-1">{{ offer.serial_number }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Updated At: <span class="font-weight-bold ml-1">{{ offer.updated_at }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Lead Source Type Name: <span class="font-weight-bold ml-1">{{ offer.lead_source_type.name
                    }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Client Name: <span class="font-weight-bold ml-1">{{ offer.customer.first_name + ' ' +
                            offer.customer.last_name
                    }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Client Whatsapp Number: <span class="font-weight-bold ml-1">{{ offer.customer.whatsapp_number
                    }}</span></b-col>
                    <hr />
                </b-row>
            </b-col>
            <b-col lg="6" sm="12">
                <b-row class="my-1">
                    <b-col> Estimated Cost: <span class="font-weight-bold ml-1">{{ numberWithCommas(offer.total_cost) }}</span>
                    </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Status: <span class="font-weight-bold ml-1">{{ offer.status }}</span> </b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Created At: <span class="font-weight-bold ml-1">{{ offer.created_at }}</span></b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Location Name: <span class="font-weight-bold ml-1">{{ offer.location.name }}</span></b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Measurement Type Name: <span class="font-weight-bold ml-1">{{ offer.measurement_type.name
                    }}</span></b-col>
                    <hr />
                </b-row>
                <b-row class="my-1">
                    <b-col> Client Phone Number: <span class="font-weight-bold ml-1">{{ offer.customer.phone_number
                    }}</span></b-col>
                    <hr />
                </b-row>
            </b-col>
        </b-row>
        <!-- Images -->
        <b-row v-if="getImages(offer.media).length > 0">
            <b-col class="mb-2" cols="12">
                <h4>Images :</h4>
            </b-col>
            <b-col v-for="image in getImages(offer.media)" :key="image.id" cols="4">
                <img :src="image.original_url" />
            </b-col>
        </b-row>
        <!-- Files -->
        <b-row v-if="getFiles(offer.media).length > 0">
            <b-col class="mt-3 mb-1" cols="12">
                <h4>Files :</h4>
            </b-col>
            <b-col v-for="file in getFiles(offer.media)" :key="file.id" cols="12">
                <p class="cursor-pointer" @click="download(file.original_url)">{{ file.original_url }}</p>
            </b-col>
        </b-row>
    </b-card-text>
</template>

<script>
import { BCardText, BRow, BCol } from 'bootstrap-vue'

export default {
    name: 'data-preview',
    components: { BCardText, BRow, BCol },
    props: ['offer'],
    methods: {
        getImages(media) {
            return media.filter(el => el.mime_type.includes('image')).map(el => ({ ...el, original_url: `${el.original_url}` }));
        },
        getFiles(media) {
            return media.filter(el => !el.mime_type.includes('image')).map(el => ({ ...el, original_url: `${el.original_url}` }));
        },
        download(url) {
            window.open(url, '_blank').focus();
        },
        numberWithCommas(total) {
            return total.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
        }
    },
}
</script>

<style scoped>
img {
    width: 100%;
}

@media (max-width: 576px) {
    span {
        margin: 0 !important;
        display: block;
    }

    hr {
        margin-top: 1rem;
        margin-bottom: 1rem;
        border: 0;
        width: 100%;
        border-top: 2px solid #f3d46a;
    }
}
</style>
