import CrudService from "@/services/shared/http/CrudService";

export default class OfferService extends CrudService {
    constructor(params = {}) {
        super("offers", params);
    }

    renew(id) {
        return this.api.PATCH(`offer-renew/${id}`);
    }

    updateJobCard(id, value) {
        return this.api.PATCH(`update-job-card/${id}`, {
            job_card: value,
        });
    }

    updateOfferStatus(id, value, rejectReason = undefined) {
        return this.api.PATCH(`update-offer-progress/${id}`, {
            offer_progress: value,
            reject_reason: rejectReason
        });
    }

    previewPDF(id, withStamp) {
        return this.api.GET(`preview-quotation-template/${id}` + '?with_stamp=' + (+withStamp))
    }

    generateQuotationPdf(id, fileName, withStamp) {
        const token = localStorage.getItem("accessToken");
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = process.env.VUE_APP_API_URL + `/api/download-quotation-template/${id}` + '?with_stamp=' + (+withStamp)

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        return fetch(file, {headers})
            .then((response) => response.blob())
            .then((blobby) => {
                let objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
            });
    }

    getTableOfQuantity(id) {
        return this.api.GET('offer-quantity/' + id)
    }

    updateTableOfQuantity(offerId, data) {
        return this.api.PUT('offer-quantity/' + offerId, data, {}, false)
    }

    generateTableOfQuantityZIP(id, fileName) {
        const token = localStorage.getItem("accessToken");
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = process.env.VUE_APP_API_URL + `/api/download-zip-file-quantity-table/${id}`;

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        return fetch(file, {headers})
            .then((response) => response.blob())
            .then((blobby) => {
                console.log(blobby)
                let objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = fileName;
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
            });
    }

    extractTable(id, fileName) {
        const token = localStorage.getItem("accessToken");
        let anchor = document.createElement("a");
        document.body.appendChild(anchor);
        let file = process.env.VUE_APP_API_URL + `/api/offer-quantity-excel/${id}`;

        let headers = new Headers();
        headers.append("Authorization", `Bearer ${token}`);

        return fetch(file, {headers})
            .then((response) => response.blob())
            .then((blobby) => {
                console.log(blobby)
                let objectUrl = window.URL.createObjectURL(blobby);
                anchor.href = objectUrl;
                anchor.download = fileName.replace('zip', 'xlsx');
                anchor.click();
                window.URL.revokeObjectURL(objectUrl);
            });
    }

}
