<template>
    <div>
        <div v-if="loading" class="loader-overlay">
            <b-spinner style="width: 4rem; height: 4rem;" variant="primary" />
        </div>
        <img style="margin-top: 5%;" :src="require('@/assets/images/logo/logo.png')" />
        <b-container class="flex text-center" align-v="center">
            <section class="p-3" v-if="offer">
                <data-preview :offer="offer" />
                <b-button variant="primary" @click="renew()"> Renew </b-button>
            </section>
        </b-container>
    </div>
</template>

<script>
import DataPreview from "@/views/components/seller/DataPreview.vue";
import OfferService from "@/services/OfferService";
import { BContainer, BSpinner, BButton } from 'bootstrap-vue'

export default {
    name: "Renew",
    components: { DataPreview, BContainer, BSpinner, BButton },
    data() {
        return {
            offerService: new OfferService(),
            id: this.$route.params.id,
            loading: undefined,
            offer: undefined
        }
    },
    methods: {
        getOffer() {
            this.loading = true;
            this.offerService.show(this.id).then(res => {
                this.offer = res;
            }).catch(err => { }).finally(() => {
                this.loading = false;
            });
        },
        renew() {
            this.loading = true;
            this.offerService.renew(this.id).then(res => {
                this.$router.push({ name: "create-offer" });
            }).catch(err => {
                console.warn(err);
            }).finally(() => {
                this.loading = false;
            });
        }
    },
    mounted() {
        this.getOffer();
    }
}
</script>

<style scope>
html body {
    background-color: #eceff1;
}

.flex {
    display: flex;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
}

section {
    background-color: #fff;
    min-height: 40vh;
    min-width: 90vw;
}

.loader-overlay {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    z-index: 99;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: wait;
}

img {
    width: 76px;
    margin: auto;
    display: block;
}
</style>